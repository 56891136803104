/**
 * @prettier
 */

import '@babel/polyfill';
import 'es6-shim';
import React from 'react';
import ReactDOM from 'react-dom';

import Annotations from './modules/annotations';
import ReadingEnvironment from './modules/reading';
import registerServiceWorker from './registerServiceWorker';

registerServiceWorker();

// Use components on wordpress frontend
window.app_modules = {
  React, // Make React accessible from the base template
  ReactDOM, // Make ReactDOM accessible from the base template

  // Add all frontend views here
  Annotations,
  ReadingEnvironment,
};

const components = ["Annotations", "ReadingEnvironment"];


const init = () => {
  for (let i = 0; i < components.length; i++) {
    if (document.getElementById(components[i])) {
      ReactDOM.render(
        React.createElement(window.app_modules[components[i]]),
        document.getElementById(components[i])
      );
    }
  }
}


window.onload = () => {
  (function($) {
    $(document).ready(function() {
			setTimeout(() => {
	      init();
			}, 0);
    });
  })(jQuery);
};
