/**
 * @prettier
 */

import thunk from 'redux-thunk';
import { composeWithDevTools as composeEnhancers } from 'redux-devtools-extension/logOnlyInProduction';
import { createLogger } from 'redux-logger';
import { createStore, applyMiddleware } from 'redux';

import rootReducer from '../reducers';

const configureStore = preloadedState => {
	const middleware =
		process.env.NODE_ENV === 'production' || 'test'
			? [thunk]
			: [thunk, createLogger()];
	const store = createStore(
		rootReducer,
		preloadedState,
		composeEnhancers(applyMiddleware(...middleware))
	);

	if (module.hot) {
		// Enable Webpack hot module replacement for reducers
		module.hot.accept('../reducers', () => {
			const nextRootReducer = require('../reducers').default; // eslint-disable-line global-require
			store.replaceReducer(nextRootReducer);
		});
	}

	return store;
};

const store = configureStore();

export default configureStore;
export { store };
