/**
 * @prettier
 */

import React, { useCallback, useContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { EditorState } from 'draft-js';

// components
import AddTooltipMenuItemButton from '../AddTooltipMenuItemButton';

import insertEntity from '../../../../lib/insertEntity';
import useClickListener from '../../../../lib/useClickListener';
import { 
	AddTooltipDispatch,
	HIDE_ADD_TOOLTIP_MENU,
} from '../constants';

function UrlEntry({ addEntity, reset }) {
	const dispatch = useContext(AddTooltipDispatch);
	const input = useRef({});
	const [src, setSrc] = useState('');
	
	const closeMenu = useCallback(evt => {
		if (evt) {
			try {
				evt.preventDefault();
				evt.stopPropagation();
			} catch (err) {}
		}
		reset();
		dispatch({
			type: HIDE_ADD_TOOLTIP_MENU,
		});
	});

	const handleChange = useCallback(evt => {
		setSrc(evt.target.value);
	});
	
	const handleClick = evt => {
		evt.preventDefault();
		evt.stopPropagation();

		input.current.focus();
	};
	
	const handleSubmit = evt => {
		evt.preventDefault();
		evt.stopPropagation();

		addEntity(src);
	};
	
	const handleKeyUp = useCallback(evt => {
		if (evt.key === 'Enter') {
			handleSubmit(evt);
		} else if (evt.key === 'Escape') {
			reset();
		}
	});

	useClickListener(input, closeMenu);

	return (
		<div
			onClick={handleClick}
			style={{
				display: 'flex',
				height: '100%',
				minHeight: 40,
				minWidth: 400,
				padding: 20,
				width: '100%',
			}}
		>
			<input
				autoFocus
				className="linkTextInput"
				onChange={handleChange}
				onKeyUp={handleKeyUp}
				ref={input}
				style={{ minWidth: 400, padding: 10 }}
				value={src}
			/>
		</div>
	);
}

UrlEntry.propTypes = {
	addEntity: PropTypes.func,
	reset: PropTypes.func,
};

AddVideoButton.propTypes = {
	closeMenu: PropTypes.func.isRequired,
	description: PropTypes.string.isRequired,
	icon: PropTypes.element.isRequired,
	editorState: PropTypes.instanceOf(EditorState).isRequired,
	setEditorState: PropTypes.func.isRequired,
	setParentContent: PropTypes.func.isRequired,
	videoType: PropTypes.oneOf(['vimeo', 'youtube']).isRequired,
};

function AddVideoButton({ 
	closeMenu,
	description,
	icon,
	editorState,
	setEditorState,
	setParentContent,
	videoType,
}) {
	const handleAddUrl = useCallback(src => {
		const newEditorState = insertEntity(editorState, {
			entityType: 'VIDEO',
			entityData: { src, videoType },
		});
		setEditorState(newEditorState);

		window.requestAnimationFrame(() => setParentContent(null));
		window.requestAnimationFrame(closeMenu);
	});

	const handleClick = useCallback(_evt => {
		setParentContent(
			<UrlEntry addEntity={handleAddUrl} reset={setParentContent} />
		);
	});

	return (
		<AddTooltipMenuItemButton
			className="AddTooltipMenuItemButton"
			onClick={handleClick}
		>
			{icon}
			<span>{description}</span>
		</AddTooltipMenuItemButton>
	);
}

export default AddVideoButton;
