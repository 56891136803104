/**
 * @prettier
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { EditorState } from 'draft-js';
import { FaVimeo, FaYoutube } from 'react-icons/fa';

// components
import AddImageButton from './AddImageButton';
import AddDividerButton from './AddDividerButton';
import AddVideoButton from './AddVideoButton';

// import AddItemButton from './AddItemButton';
// import AddSourceTextButton from './AddSourceTextButton';
// import AddSketchfabButton from './AddSketchfabButton';

function AddTooltipMenu({ closeMenu, editorState, setEditorState, visible }) {
	const [content, setContent] = useState(null);
	const className = classnames('addTooltipMenu', {
		addTooltipMenuVisible: visible || content,
	});
	
	return (
		<div className={className}>
			{content || (
				<React.Fragment>
					<div className="addTooltipMenuSection ml2">
						<div className="addTooltipMenuSectionItems flex items-center">
							<AddImageButton
								closeMenu={closeMenu}
								editorState={editorState}
								setEditorState={setEditorState}
							/>
							<AddDividerButton
								closeMenu={closeMenu}
								editorState={editorState}
								setEditorState={setEditorState}
							/>
						</div>
					</div>
					<div className="addTooltipMenuSection ml2">
						<div className="addTooltipMenuSectionItems flex items-center">
							<AddVideoButton
								closeMenu={closeMenu}
								description="YouTube"
								editorState={editorState}
								icon={<FaYoutube />}
								setEditorState={setEditorState}
								setParentContent={setContent}
								videoType="youtube"
							/>
							<AddVideoButton
								closeMenu={closeMenu}
								description="Vimeo"
								editorState={editorState}
								icon={<FaVimeo />}
								setEditorState={setEditorState}
								setParentContent={setContent}
								videoType="vimeo"
							/>
						</div>
					</div>
				</React.Fragment>
			)}
			<div className="addTooltipMenuFooter">More options coming soon.</div>
		</div>
	);
}

AddTooltipMenu.propTypes = {
	closeMenu: PropTypes.func.isRequired,
	editorState: PropTypes.instanceOf(EditorState).isRequired,
	setEditorState: PropTypes.func.isRequired,
	visible: PropTypes.bool,
};

export default AddTooltipMenu;
