import React from 'react';
import autoBind from 'react-autobind';

import Annotation from './Annotation';

class AnnotationThread extends React.Component {

  constructor(props) {
    super(props);

    let shortAnnotation = false;

    if (props.annotations.length === 1) {
      if (props.annotations[0].post_content.length < 220 && props.annotations[0].replies.length === 0) {
        shortAnnotation = true;
      }
    }

    this.state = {
      open: false,
      top: 0,
      shortAnnotation,
    };
  }

  componentDidMount() {
    // position annotation by paragraph N
    const $paragraphs = $("#main p");
    const $annotatedParagraph = $($paragraphs[this.props.n]);
    const $contentSection = $("#main");
    $annotatedParagraph.addClass("annotated");

    this.setState({
      top: $annotatedParagraph.offset().top - $contentSection.offset().top,
    });
  }

  showMore(open) {
    if ( typeof open === "boolean") {
      this.setState({
        open,
      });
    } else {
      this.setState({
        open: !this.state.open,
      });
    }
  }

  handleReplyCancel() {
    this.setState({
      open: false,
    });
  }

  render() {

    return (
      <div
        className={`
          annotationThread
          ${this.state.open ? 'annotationThreadOpen' : ''}
          ${this.state.shortAnnotation ? 'annotationThreadShort' : ''}
        `}
        style={{
          top: this.state.top,
        }}
      >
        {/*
        <button className="annotationMarker">
          +
        </button>
        */}

        <div className="annotations">
          {this.props.annotations.map(annotation => (
      			<Annotation
      				key={annotation.ID}
              showMore={this.showMore.bind(this)}
      				{...annotation}
              handleReplyCancel={this.handleReplyCancel.bind(this)}
      			/>
          ))}
        </div>
        {!this.state.shortAnnotation ?
          <button className="readMoreToggle" onClick={this.showMore.bind(this)}>
            {this.state.open ?
              <React.Fragment>
                Show Less
              </React.Fragment>
            :
              <React.Fragment>
                Show More
              </React.Fragment>
            }
          </button>
        : ''}
      </div>
    );
  };
}

export default AnnotationThread;
