/**
 * @prettier
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { getVisibleSelectionRect } from 'draft-js';

const DOWN_KEY = 40;
const UP_KEY = 38;

const ENTER_KEY = 13;
const ESC_KEY = 27;
const TAB_KEY = 9;

const INTERCEPT_KEYS = [DOWN_KEY, ENTER_KEY, ESC_KEY, TAB_KEY, UP_KEY];

export default function TagSuggestions({
	containerEl = {},
	currentText = '',
	getSuggestions = async () => {},
	onSelect = () => {},
}) {
	const [anchorPosition, setAnchorPosition] = useState({ left: 0, top: 0 });
	const [selectedIndex, setSelectedIndex] = useState(0);
	const [suggestions, setSuggestions] = useState([]);

	const handleKeyDown = e => {
		const keyCode = e.keyCode;

		if (INTERCEPT_KEYS.includes(keyCode)) {
			e.preventDefault();
			e.stopPropagation();
		}

		switch (keyCode) {
			case UP_KEY:
				const dec = selectedIndex - 1;

				setSelectedIndex(dec < 0 ? 0 : dec);
				break;
			case DOWN_KEY:
				const inc = selectedIndex + 1;

				setSelectedIndex(
					inc >= suggestions.length ? suggestions.length - 1 : inc
				);
				break;
			case ESC_KEY:
				onSelect(false);
				break;
			case ENTER_KEY:
				onSelect(suggestions[selectedIndex]);
				break;
			case TAB_KEY:
				onSelect(suggestions[selectedIndex]);
				break;
		}
	};

	const _getSuggestions = async () => {
		if (!currentText) return setSuggestions([]);

		try {
			const json = await getSuggestions(currentText);

			if (json) {
				const suggestionsUpdate = json.results
					.map(t => t.name)
					.filter(s => s.startsWith(currentText))
					.sort();

				if (suggestionsUpdate.includes(currentText)) {
					setSuggestions(suggestionsUpdate);
				} else {
					setSuggestions([currentText, ...suggestionsUpdate]);
				}
			}
		} catch (err) {
			console.error('Error fetching autocomplete suggestions.');
			console.error(err.toString());

			setSuggestions([]);
		}
	};

	// we need this effect to run on every render,
	// otherwise the selectedIndex never changes
	// from the default
	useEffect(() => {
		window.addEventListener('keydown', handleKeyDown);

		return function cleanUp() {
			window.removeEventListener('keydown', handleKeyDown);
		};
	});

	useEffect(() => {
		if (
			suggestions.length > 0 &&
			selectedIndex > 0 &&
			selectedIndex >= suggestions.length
		) {
			setSelectedIndex(suggestions.length - 1);
		}
	}, [suggestions.join()]);

	useEffect(() => {
		if (currentText) {
			const selectionRect = getVisibleSelectionRect(window);
			const containerRect = containerEl.getBoundingClientRect();

			if (containerRect && selectionRect) {
				const offsetTop = -30;

				const top =
					containerEl.offsetTop -
					offsetTop +
					(selectionRect.top - containerRect.top);

				const left =
					containerEl.offsetLeft +
					(selectionRect.left - containerRect.left) +
					selectionRect.width / 2;

				setAnchorPosition({ left, top });
			}

			_getSuggestions();
		}
	}, [currentText]);

	const handleClick = s => e => {
		e.preventDefault();
		e.stopPropagation();

		onSelect(s);
	};

	return (
		<div
			className="br py1 shadow-3 z5"
			id="@@reading-env/comment-form/hashtag-menu"
			style={{ position: 'absolute', ...anchorPosition }}
		>
			{suggestions.map((s, i) => (
				<div
					className={cn('full-width pointer px2', {
						'bg-gray': i === selectedIndex,
					})}
					key={`${s}-${i}`}
					onClick={handleClick(s)}
					onMouseEnter={() => setSelectedIndex(i)}
				>
					<a className="fw-normal" href="#">
						{s}
					</a>
				</div>
			))}
		</div>
	);
}

TagSuggestions.propTypes = {
	containerEl: PropTypes.object,
	currentText: PropTypes.string,
	getSuggestions: PropTypes.func,
	onSelect: PropTypes.func,
};
