import * as types from '../actions';

const defaultState = {
	editorRef: null,
};

export default (state = defaultState, action) => {
	switch (action.type) {
	case types.SET_EDITOR_REF:
		return {
			...state,
			editorRef: action.editorRef,
		};
	case types.SET_EDITOR_STATE:
		return {
			...state,
			editorState: action.editorState,
		};
	case types.SET_LINK_INPUT:
		return {
			...state,
			linkInput: action.linkInput,
		};
	case types.SET_TOOLTIP:
		return {
			...state,
			tooltip: action.tooltip,
		};
	case types.SET_ADD_TOOLTIP:
		return {
			...state,
			addTooltip: action.addTooltip,
		};
	default:
		return state;
	}
};
