import React from 'react';
import ReactDOM from 'react-dom';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';

import EpubReader from './components/EpubReader';



const ReadingEnvironment = () => (
	<MuiThemeProvider>
		<EpubReader />
	</MuiThemeProvider>
);

export default ReadingEnvironment;
