import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { useSwipeable } from "react-swipeable";
import autoBind from 'react-autobind';
import Epub from "epubjs/lib/index";
import { DebounceInput } from 'react-debounce-input';
import { Range, getTrackBackground } from 'react-range';

import EpubView from "../EpubView";

import defaultStyles from "./style";

const Loading = () => (
  <div className="readingEnvLoadingView">
		<div className="ReadingEnv">
			<div className="readingEnvLoadingContent">
				<div className="readingEnvLoadingHeader" />
				<div className="readingEnvLoadingLine" />
				<div className="readingEnvLoadingLine" />
				<div className="readingEnvLoadingLine" />
				<div className="readingEnvLoadingLine" />
				<div className="readingEnvLoadingLine" />
			</div>
		</div>
  </div>
);

const Swipeable = ({children, ...props}) => {
  const handlers = useSwipeable(props);
  return (<div { ...handlers }>{children}</div>);
}

class TocItem extends PureComponent {
  setLocation = () => {
    this.props.setLocation(this.props.href);
  };
  render() {
    const { label, styles } = this.props;
    return (
      <button onClick={this.setLocation} style={styles} dangerouslySetInnerHTML={{__html: label}}>
      </button>
    );
  }
}

TocItem.propTypes = {
  label: PropTypes.string,
  href: PropTypes.string,
  setLocation: PropTypes.func,
  styles: PropTypes.object
};

class ReactReader extends PureComponent {
  constructor(props) {
    super(props);
    this.readerRef = React.createRef();
    let bookIsSaved = false;

    window.__orpheus__ = window.__orpheus__ || {};
    bookIsSaved = window.__orpheus__.bookIsSaved;

    this.state = {
      expandedToc: false,
      toc: false,
      searchText: '',
      searchDropdownShown: false,
      authDropdownShown: false,
      settingsDropdownShown: false,
      searchResults: [],
      searchLoading: false,
      sliderLocationValues: [0],
      bookIsSaved,
    };
    autoBind(this);
  }

  toggleToc = () => {
    this.setState({
      expandedToc: !this.state.expandedToc
    });
  };

  next = () => {
    const node = this.readerRef.current;
    node.nextPage();
    this.updatePagination(this.readerRef.current.rendition.location);
  };

  prev = () => {
    const node = this.readerRef.current;
    node.prevPage();
    this.updatePagination(this.readerRef.current.rendition.location);
  };

  onTocChange = toc => {
    const { tocChanged } = this.props;
    this.setState(
      {
        toc: toc
      },
      () => tocChanged && tocChanged(toc)
    );
  };

  renderToc() {
    const { toc, expandedToc } = this.state;
    const { styles, title, slug } = this.props;

    const stripTagsRegex = /(<([^>]+)>)/ig;

    return (
      <div>
        <div style={styles.tocArea}>
          <div style={styles.tocHead}>
            <a style={styles.tocBackLink} href="/books/">
              {'⎋ Books'}
            </a>
            <a href={`/book/${slug}`}>
              <h1 style={styles.tocTitle} dangerouslySetInnerHTML={{__html: title}}>
              </h1>
            </a>
          </div>
          <div style={styles.toc}>
            {toc.map((item, i) => (
              <TocItem
                {...item}
                key={i}
                setLocation={this.setLocation}
                styles={styles.tocAreaButton}
              />
            ))}
          </div>
        </div>
        {expandedToc && (
          <div style={styles.tocBackground} onClick={this.toggleToc} />
        )}
      </div>
    );
  }

  setLocation = loc => {
    const { locationChanged } = this.props;
    this.setState(
      {
        expandedToc: false
      },
      () => locationChanged && locationChanged(loc)
    );

    setTimeout(() => {
      this.updatePagination(this.readerRef.current.rendition.location);
    }, 500);
  };

  renderTocToggle() {
    const { expandedToc } = this.state;
    const { styles } = this.props;
    return (
      <button
        className="tocToggleButton"
        style={Object.assign(
          {},
          expandedToc ? styles.tocButtonExpanded : {}
        )}
        onClick={this.toggleToc}
      >
        <span
          style={Object.assign({}, styles.tocButtonBar, styles.tocButtonBarTop)}
        />
        <span
          style={Object.assign({}, styles.tocButtonBar, styles.tocButtonBottom)}
        />
      </button>
    );
  }

  handleSearchChange(e) {
    const q = e.target.value;
    const self = this;

    function doSearch(q) {
      self.setState({ searchLoading: true });
      return Promise.all(
          self.book.spine.spineItems.map(item => item.load(self.book.load.bind(self.book)).then(item.find.bind(item, q)).finally(item.unload.bind(item)))
      ).then(results => {
        Promise.resolve([].concat.apply([], results))
        self.setState({
          searchResults: results,
        });
        self.setState({ searchLoading: false });
      });
    };

    if (q.length) {
      const { url, tocChanged, epubInitOptions } = this.props;
      this.book = new Epub(url, epubInitOptions);
      this.book.loaded.navigation.then(({ toc }) => {
        doSearch(q);
      });
      this.setState({
        searchText: q,
      });

    } else {
      this.setState({
        searchText: q,
        searchResults: [],
      });
    }
  }

  showAuthMenu() {
    this.setState({authDropdownShown: true});
  }
  hideAuthMenu() {
    this.setState({authDropdownShown: false});
  }

  showSearchMenu() {
    this.setState({searchDropdownShown: true});
  }
  hideSearchMenu(e) {
    const $target = $(e.target);

    if (!$target.hasClass(".-readingHeaderMenu") && !$target.closest(".-readingHeaderMenu").length) {
      this.setState({searchDropdownShown: false});
    }
  }

  handleBookmark(e) {
    window.__orpheus__ = window.__orpheus__ || {};
    const userId = window.__orpheus__.userId;

    e.preventDefault();

    if (userId && userId.length) {
      this.setState({
        bookIsSaved: !this.state.bookIsSaved,
      });

      const bookID = $("#bookID").data().id;

      $.post('/wp-json/chs/v1/bookshelf', {
        bookID,
        _wpnonce: chsScriptVars.nonce,
      }, (res) => {
        console.log(res);
      });
    } else {

      window.location = `/wp-login.php?redirect_to=/read/${this.props.slug}`
    }
  }

  initSliderPosition() {
    // all locations have been generated, init slider position
    const percentage = this.readerRef.current.book.locations.percentageFromCfi(this.readerRef.current.location);
    const currentPage = this.readerRef.current.book.locations.locationFromCfi(this.readerRef.current.location);
    const totalPages = this.readerRef.current.book.locations.total;

    this.setState({
      sliderLocationValues: [percentage * 100],
      currentPage,
      totalPages,
    });
  }

  handleSliderLocationChange(sliderLocationValues) {
    const cfi = this.readerRef.current.book.locations.cfiFromPercentage(sliderLocationValues[0] / 100);

    if (cfi) {
      this.setLocation(cfi);
      const currentPage = this.readerRef.current.book.locations.locationFromCfi(cfi);
      this.setState({
        sliderLocationValues,
        currentPage,
      });
    }
  }

  updatePagination(loc) {
    let percentage = 0;
    let currentPage = 0;
    if (typeof loc !== "string" && 'start' in loc) {
      percentage = this.readerRef.current.book.locations.percentageFromCfi(loc.start.cfi);
      currentPage = this.readerRef.current.book.locations.locationFromCfi(loc.start.cfi);
    } else {
      percentage = this.readerRef.current.book.locations.percentageFromCfi(loc);
      currentPage = this.readerRef.current.book.locations.locationFromCfi(loc);
    }

    this.setState({
      sliderLocationValues: [percentage * 100],
      currentPage,
    });
  }

  render() {
    const {
      title,
      showToc,
      loadingView,
      styles,
      locationChanged,
      swipeable,
      epubViewStyles,
      ...props
    } = this.props;

    const {
      toc, expandedToc, searchDropdownShown, authDropdownShown, bookIsSaved,
      searchResults, searchText,
    } = this.state;

    window.__orpheus__ = window.__orpheus__ || {};
    const userId = window.__orpheus__.userId;
    const username = window.__orpheus__.username;
    const userAvatar = window.__orpheus__.userAvatar;

    return (
      <div style={styles.container}>
        <div
          style={Object.assign(
            {},
            styles.readerArea,
            expandedToc ? styles.containerExpanded : {}
          )}
        >
          <header className="readingHeader headroom" data-html2canvas-ignore="true">
            {showToc && this.renderTocToggle()}
            <div className="readingHeaderTitle" dangerouslySetInnerHTML={{__html: title}}></div>
            <ul className="headerExternalNav">
              <li>
                  <a
                    href="#"
                    className={`
                      headerExternalButton headerExternalButtonSearch bookmarkButton chsButton -bookButton -saveButton
                      ${bookIsSaved ? '-bookIsSaved' : ''}
                    `}
                    onClick={this.handleBookmark.bind(this)}
                  >
                  <i className="mdi mdi-bookmark-outline"></i>
                  <i className="mdi mdi-bookmark"></i>
                </a>
              </li>


              {(userId && userId.length) ?
                <li>
                  <button className="headerExternalButton headerMenuToggleButton" tabIndex="-1" role="menuitem" >
                    <div className="avatarChip" style={{ backgroundImage: `url("${userAvatar}")` }}></div>
                      <div className={`dropdownHeaderMenu -authHeaderMenu -readingHeaderMenu`} role="tooltip">
                      <div className="jss88 jss92 jss89" id="menu-list-grow" >
                        <ul className="jss115 jss116 headerExternalSecondaryNav" role="menu" >
                          <li tabIndex="0">
                            <a className="jss7 jss122 jss125 jss130 jss131 jss119 jss120" tabIndex="-1" role="menuitem" href="/saved/" >
                              Saved
                            </a>
                          </li>
                          <li tabIndex="-1" >
                            <a href="https://forms.gle/WC9ZnPC9iyF89RmB6" className="jss7 jss122 jss125 jss130 jss131 jss119 jss120" tabIndex="-1" role="menuitem">
                              Help
                            </a>
                          </li>
                          <li tabIndex="-1" >
                            <a href="/wp-login.php?action=logout" className="jss7 jss122 jss125 jss130 jss131 jss119 jss120" tabIndex="-1" role="menuitem">
                              Logout {username}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </button>
                </li>
              :
                <li >
                  <button className="jss7 jss1 headerExternalButton headerMenuToggleButton"  type="button" aria-owns="menu-list-grow" aria-haspopup="true">
                    <span className="jss6">
                      <svg className="jss10" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path d="M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"></path>
                      </svg>
                    </span>
                    <span className="jss19"></span>
                      <div className={`dropdownHeaderMenu -authHeaderMenu -readingHeaderMenu `} role="tooltip">
                      <div className="jss88 jss92 jss89" id="menu-list-grow">
                        <ul className="jss115 jss116 headerExternalSecondaryNav" role="menu">
                          <li >
                            <a className="jss7 jss122 jss125 jss130 jss131 jss119 jss120"  role="menuitem" href="/wp-login.php">
                              Log in
                            </a>
                          </li>
                          <li >
                            <a className="jss7 jss122 jss125 jss130 jss131 jss119 jss120"  role="menuitem" href="/wp-login.php?action=register">
                              Sign Up
                            </a>
                          </li>
                          <li >
                            <a href="https://forms.gle/5k98LtqRKtV9NmR26" className="jss7 jss122 jss125 jss130 jss131 jss119 jss120"  role="menuitem">
                              Help
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </button>
                </li>
              }

              <li>
                <button className="headerExternalButton headerExternalButtonSearch" type="button" onClick={this.showSearchMenu.bind(this)}>
                  <svg viewBox="0 0 30 30">
                    <path d="M12.8841,0 C19.9997919,0 25.7682,5.75940898 25.7682,12.864 C25.7682,15.8364395 24.7584733,18.5734126 23.0628032,20.751641 L28.5175168,26.1989723 C29.1464611,26.8269354 29.1464611,27.8450646 28.5175168,28.4730277 C27.8885726,29.1009908 26.8688524,29.1009908 26.2399082,28.4730277 L26.2399082,28.4730277 L20.7840655,23.0268238 C18.6024335,24.7198485 15.8611839,25.728 12.8841,25.728 C5.76840806,25.728 0,19.968591 0,12.864 C0,5.75940898 5.76840806,0 12.8841,0 Z M12.8841,3.216 C7.54733104,3.216 3.221025,7.53555673 3.221025,12.864 C3.221025,18.1924433 7.54733104,22.512 12.8841,22.512 C18.220869,22.512 22.547175,18.1924433 22.547175,12.864 C22.547175,7.53555673 18.220869,3.216 12.8841,3.216 Z" id="path-1"></path>
                  </svg>
                </button>

                  {searchDropdownShown ?
                    <div className="searchDropdownBackground" onClick={this.hideSearchMenu.bind(this)}>
                      <div className={`dropdownHeaderMenu -searchMenu -readingHeaderMenu -readingHeaderMenuShown`} role="tooltip">

                        <DebounceInput
                          type="text"
                          onChange={this.handleSearchChange.bind(this)}
                          value={this.state.searchText}
                          placeholder="Search book . . ."
                          minLength={3}
                          debounceTimeout={300}
                        />

                        {this.state.searchLoading ?
                          <div className="searchLoading">
                            <div className="searchLoadingLine">
                            </div>
                            <div className="searchLoadingLine">
                            </div>
                          </div>

                        :
                          <div className="jss88 jss92 jss89" id="menu-list-grow">
                            <ul className="jss115 jss116 headerExternalSecondaryNav searchResults" role="menu">
                              {searchResults.map((chapter, i) => (
                                <React.Fragment key={i}>
                                  {chapter.map((result, j) => (
                                    <li tabIndex="0" key={j}>
                                      <a className="jss7 jss122 jss125 jss130 jss131 jss119 jss120" tabIndex="-1" role="menuitem" href="#" onClick={this.setLocation.bind(this, result.cfi)}>
                                        {result.excerpt}
                                      </a>
                                    </li>
                                  ))}
                                </React.Fragment>
                              ))}
                              {(searchText && searchText.length && searchResults.length === 0) ?
                                <li>
                                  No results for your search.
                                </li>
                              : ''}
                            </ul>
                          </div>
                        }
                      </div>
                    </div>
                  :
                  ''
                }
              </li>

            </ul>
          </header>

          <Swipeable
            onSwipedRight={this.prev}
            onSwipedLeft={this.next}
            trackMouse
          >
            <div className="readingContent">
              <EpubView
                ref={this.readerRef}
                loadingView={loadingView}
                styles={epubViewStyles}
                {...props}
                tocChanged={this.onTocChange}
                initSliderPosition={this.initSliderPosition.bind(this)}
                updatePagination={this.updatePagination.bind(this)}
                next={this.next.bind(this)}
                prev={this.prev.bind(this)}
              />
              {swipeable && <div style={styles.swipeWrapper} />}
            </div>
          </Swipeable>
          <button
            className="bookNavButton -prevButton"
            style={Object.assign({}, styles.arrow, styles.prev)}
            onClick={this.prev}
          >
            ‹
          </button>
          <button
            className="bookNavButton -nextButton"
            style={Object.assign({}, styles.arrow, styles.next)}
            onClick={this.next}
          >
            ›
          </button>

          <div className="readingProgress">
            <Range
              step={0.1}
              min={0}
              max={100}
              values={this.state.sliderLocationValues}
              onChange={this.handleSliderLocationChange.bind(this)}
              renderTrack={({ props, children }) => (
                <div
                  ref={props.ref}
                  style={{
                    height: '8px',
                    width: '100%',
                    borderRadius: '4px',
                    background: getTrackBackground({
                      values: this.state.sliderLocationValues,
                      colors: ['#999', '#eee'],
                      min: 0,
                      max: 100,
                    }),
                    alignSelf: 'center'
                  }}
                >
                  {children}
                </div>
              )}
              renderThumb={({ props }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: '20px',
                    width: '20px',
                    backgroundColor: '#999',
                    borderRadius: '100%',
                    outline: 'none',
                  }}
                />
              )}
            />
            {(this.state.currentPage && this.state.totalPages) ?
              <div className="progressLabel">
                Page {this.state.currentPage} of {this.state.totalPages}
              </div>
            : ''}
          </div>
        </div>
        {showToc && toc && this.renderToc()}
      </div>
    );
  }
}

ReactReader.defaultProps = {
  loadingView: <Loading />,
  locationChanged: null,
  tocChanged: null,
  showToc: true,
  styles: defaultStyles
};

ReactReader.propTypes = {
  title: PropTypes.string,
  loadingView: PropTypes.element,
  showToc: PropTypes.bool,
  locationChanged: PropTypes.func,
  tocChanged: PropTypes.func,
  styles: PropTypes.object,
  epubViewStyles: PropTypes.object,
  swipeable: PropTypes.bool
};

export default ReactReader;
