import {
	EditorState,
	Modifier,
} from 'draft-js';

import getCurrentBlock from './getCurrentBlock';

const insertNewBlock = (
	editorState,
	type = 'unstyled',
	data = {}
) => {
	const selectionState = editorState.getSelection();

	if (!selectionState.isCollapsed()) {
		return editorState;
	}

	const key = getCurrentBlock(editorState).getKey();
	let contentState = editorState.getCurrentContent();
	contentState = Modifier.splitBlock(contentState, selectionState);
	let newBlock = contentState.getBlockAfter(key);

	newBlock = newBlock.merge({ data, type });
	contentState = contentState.set(
		'blockMap',
		contentState.getBlockMap().set(newBlock.getKey(), newBlock)
	);

	return EditorState.push(editorState, contentState, 'split-block');
};

export default insertNewBlock;
