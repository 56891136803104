/**
 * @prettier
 */

import React from 'react';
import PropTypes from 'prop-types';
import autoBind from 'react-autobind';
import { CompositeDecorator } from 'draft-js';

class Gloss extends React.Component {
	static propTypes = {
		children: PropTypes.node,
		contentState: PropTypes.object,
		entityKey: PropTypes.string,
	};

	constructor(props) {
		super(props);

		this.isHover = false;
		this.state = {
			shown: false,
		};

		this.glossRef = React.createRef();

		autoBind(this);
	}

	showPopGlossOver(e) {
		this.setState({
			shown: true,
		});

		if (!this.data.showPopGlossOver) {
			return false;
		}

		return this.data.showPopGlossOver(this.glossRef.current);
	}

	hidePopGlossOver(e) {
		this.setState({
			shown: false,
		});

		if (!this.data.hidePopGlossOver) {
			return false;
		}

		return this.data.hidePopGlossOver();
	}

	render() {
		const { contentState, entityKey } = this.props;
		this.data = contentState.getEntity(entityKey).getData();

		return (
			<span
				ref={this.glossRef}
				className={`gloss ${this.state.shown ? '--gloss-shown' : ''}`}
				onMouseOver={this.showPopGlossOver}
				onMouseOut={this.hidePopGlossOver}
			>
				<span className="glossText">{this.data.glossText}</span>
				<abbr>{this.props.children}</abbr>
			</span>
		);
	}
}

const findGlossEntities = (contentBlock, callback, contentState) => {
	contentBlock.findEntityRanges(character => {
		const entityKey = character.getEntity();

		return (
			entityKey !== null &&
			contentState.getEntity(entityKey).getType() === 'GLOSS'
		);
	}, callback);
};

const glossDecorator = new CompositeDecorator([
	{
		strategy: findGlossEntities,
		component: Gloss,
	},
]);

export default glossDecorator;
export { Gloss, findGlossEntities };
