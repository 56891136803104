import React from 'react';


const NotFound = () => {
  const expressionsOfWoe = [
      'ἰὼ ἰώ',
      'αἶ, αἶ',
      'οἴμοι μοι',
      'φεῦ φεῦ',
      'ἰώ μοί μοι',
      'ὦ Ζεῦ',
      'βοᾷ βοᾷ',
      'αἰαῖ αἰαῖ',
      'ἔα ἔα',
      'ὀττοτοτοτοτοῖ',
      'ἄλγος ἄλγος βοᾷς',
      'ἐλελεῦ',
      'μὴ γένοιτο',
      'οὐαί',
    ];
  const expressionOfWoe = expressionsOfWoe[Math.floor(Math.random() * expressionsOfWoe.length)];

  return (
    <div className="archive -404">
      <div className="notFoundImages">
        <img className="illustration middle" src="/wp-content/themes/orpheus/static/images/illustration_heroes.png"/>
        <img className="illustration right" src="/wp-content/themes/orpheus/static/images/illustration_lighthouse.png"/>
      </div>
      <div className="notFoundText">
        <div className="notFoundTextInner">
          <h1>
            {expressionOfWoe}, hero!
          </h1>
          <h2 className="subtitle">
            We haven't added this book yet.
          </h2>
          <hr></hr>
          <p>
            Invoke a <a href="mailto:outreach@chs.harvard.edu">website muse</a> to steer you back on course or make your own odyssey
            <a href="/">back to the homepage</a>.
          </p>
        </div>
      </div>
    </div>
  )
};


export default NotFound;
