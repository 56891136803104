/**
 * @prettier
 */

import React from 'react';
import PropTypes from 'prop-types';
import { CompositeDecorator } from 'draft-js';

function Hashtag({ children, contentState, entityKey }) {
	return <span className="fw7 underline">{children}</span>;
}

Hashtag.propTypes = {
	children: PropTypes.any,
	contentState: PropTypes.object,
	entityKey: PropTypes.string,
};

const findHashtagEntities = (contentBlock, callback, contentState) => {
	contentBlock.findEntityRanges(character => {
		const entityKey = character.getEntity();

		return (
			entityKey !== null &&
			contentState.getEntity(entityKey).getType() === 'HASHTAG'
		);
	}, callback);
};

const HashtagDecorator = new CompositeDecorator([
	{
		component: Hashtag,
		strategy: findHashtagEntities,
	},
]);

export default HashtagDecorator;
export { Hashtag, findHashtagEntities };
