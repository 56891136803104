import {
	EditorState,
	SelectionState,
} from 'draft-js';

// via https://github.com/facebook/draft-js/issues/915#issuecomment-399532257
const removeBlock = (editorState, blockKey) => {
	const contentState = editorState.getCurrentContent();
	const blockMap = contentState.getBlockMap();
  const newBlockMap = blockMap.filter(block => block.getKey() !== blockKey);
	const newContentState = contentState.merge({
		blockMap: newBlockMap,
	});

	// const selection = editorState.getSelection();
	// const startKey = selection.getStartKey();
	// const blockBeforeKey = contentState.getBlockBefore(startKey).getKey();
	// const newSelection = SelectionState.createEmpty().merge({
	// 	...selection.toJS(),
	// 	anchorKey: blockBeforeKey,
	// 	focusKey: blockBeforeKey,
	// });

	// work in new selection state when necessary
	// return EditorState.forceSelection(EditorState.push(editorState, newContentState), newSelection);
	return EditorState.push(editorState, newContentState);
};

export default removeBlock;
