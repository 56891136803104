import React from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import $ from 'jquery';
import autobind from 'react-autobind';
import getMuiTheme from 'material-ui/styles/getMuiTheme';

import AnnotationThread from '../AnnotationThread';
import AnnotationDrawer from '../AnnotationDrawer';
import muiTheme from '../../lib/muiTheme';


class AnnotationEnvironment extends React.Component {
	constructor(props) {
		super(props);

    const userCanAnnotate = window.__orpheus__.userCanAnnotate || false;

		this.state = {
			annotationDrawerOpen: false,
			userCanAnnotate,
			annotationTooltipShown: false,
			tooltipTop: 0,
			tooltipLeft: 0,
		};

		autobind(this);
	}

	getChildContext = () => {
		return { muiTheme: getMuiTheme(muiTheme) };
	}

	componentDidMount = () => {
		$('.pageOverlay').on('click', () => this.closeAnnotationDrawer());
		$('#main').on('mouseup', () => this.showTooltip());
	}

	componentWillUnmount = () => {
	}

	openAnnotationDrawer = () => {
		this.setState({
			annotationDrawerOpen: true,
		});
	}

	closeAnnotationDrawer = () => {
		this.setState({
			annotationDrawerOpen: false,
		});
	}

	showTooltip = () => {
		const selection = window.getSelection();

		if (!this.state.userCanAnnotate) {
			if (this.state.showTooltip === true) {
				this.setState({
					showTooltip: false,
					annotationDrawerOpen: false,
				});
			}
			return;
		}

		if (selection.isCollapsed || !selection.toString().trim().length) {
			if (this.state.showTooltip === true) {
				this.setState({
					showTooltip: false,
					annotationDrawerOpen: false,
				});
			}
			return;
		}

		const getRange = selection.getRangeAt(0);
		const getRect = getRange.getBoundingClientRect();

		let top = 0;
		let paragraphN = 0;
		let parentEl = selection.getRangeAt(0).commonAncestorContainer;

		if (parentEl.tagName && parentEl.tagName.toLowerCase() === "section") {
				parentEl = getRange.startContainer.parentElement;
		}

		if (parentEl.nodeType != 1) {
				parentEl = parentEl.parentNode;
		}

    const $paragraphs = $("#main p");
		paragraphN = $paragraphs.index(parentEl);

		// get offsetRelativeToParent
		let offsetRelativeToParent = getRect.top - parentEl.getBoundingClientRect().top;

		// parent offset top - where the annotation environment starts + the bounding rect offset top from the parent elemnt + half the range height + half the tooltip.
		top = parentEl.offsetTop - $('.annotationEnvironment').offset().top  + offsetRelativeToParent + (getRect.height / 2) + 20;

		this.setState({
			showTooltip: true,
			tooltipTop: top,
			annotationDrawerOpen: false,
			paragraphN,
		});
	}

	handleShowAnnotationDrawer = () => {
		this.setState({
			annotationDrawerOpen: true,
			showTooltip: false,
		});
	}

	handleCloseAnnotationDrawer = () => {
		this.setState({
			annotationDrawerOpen: false,
			showTooltip: false,
		});
	}

	renderAnnotations = () => {
		const annotations = this.props.annotations;

		const annotationsByParagraph = [];
		annotations.forEach(annotation => {
			if (annotationsByParagraph.some(paragraph => (annotation.paragraph_n === paragraph.n))) {
				annotationsByParagraph.forEach(paragraph => {
					if (annotation.paragraph_n === paragraph.n) {
						paragraph.annotations.push(annotation);
					}
				});
			} else {
				annotationsByParagraph.push({
					n: annotation.paragraph_n,
					annotations: [annotation],
				});
			}
		});

		return annotationsByParagraph.map(paragraph => (
			<AnnotationThread
				key={paragraph.n}
				{...paragraph}
			/>
		))
	}

	render = () => {
		const { annotationDrawerOpen, tooltipTop, tooltipLeft } = this.state;
		const transform = `translate3d(${tooltipLeft}px, ${tooltipTop}px, 0px)`;

		return (
			<div className="annotationEnvironment">

				{this.state.showTooltip ?
					<button
						className="annotationCreateTooltip"
						style={{
						  transform,
						}}
						onClick={this.handleShowAnnotationDrawer.bind(this)}
					>
						<i className="mdi mdi-pencil"/>
						<span className="caret"/>
					</button>
				: ''}

				{/* div used to display the drawer */}
				{annotationDrawerOpen ?
					<AnnotationDrawer
						closeEditMode={this.handleCloseAnnotationDrawer}
						top={this.state.tooltipTop - 47}
						paragraphN={this.state.paragraphN}
					/>
				: ''}

				{/* pageOverlay for handling click */}
				<div
					className={`pageOverlay
						${annotationDrawerOpen ? 'pageOverlayActive' : ''}
					`}
					onClick={this.closeAnnotationDrawer.bind(this)}
				/>

				{this.renderAnnotations()}
			</div>
		);
	}
}

AnnotationEnvironment.childContextTypes = {
	muiTheme: PropTypes.object,
};

export default AnnotationEnvironment;
