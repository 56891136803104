/**
 * @prettier
 */

import React, { useCallback, useEffect, useReducer, useRef } from 'react';
import PropTypes from 'prop-types';
import DraftOffsetKey from 'draft-js/lib/DraftOffsetKey';
import cn from 'classnames';
import { EditorState } from 'draft-js';

// components
import AddTooltipMenu from '../AddTooltipMenu';
import AddTooltipButton from '../AddTooltipButton';

import { 
	AddTooltipDispatch,
	HIDE_ADD_TOOLTIPE_BUTTON,
	HIDE_ADD_TOOLTIP_MENU,
	SHOW_ADD_TOOLTIP_BUTTON,
	SHOW_ADD_TOOLTIP_MENU,
} from '../constants';
import useClickListener from '../../../../lib/useClickListener';
import { getEndBlock } from '../../../../lib/getCurrentBlock';
import { LIST_ITEM_BLOCK_TYPES } from '../../../../lib/draftJsBlockTypes';

// icons
import { MdAdd } from 'react-icons/md';

const OFFSET = -13;

const isInListMode = block => {
	return LIST_ITEM_BLOCK_TYPES.includes(block.getType());
};

const calculateAddTooltipPosition = (editorState, editorRef) => {
	if (!editorRef) return;

	const endBlock = getEndBlock(editorState);

	if (!endBlock) return;

	const offsetKey = DraftOffsetKey.encode(endBlock.getKey(), 0, 0);

	const node = document.querySelectorAll(
		`[data-offset-key="${offsetKey}"]`
	)[0];

	if (!node) return;

	const editorRoot = editorRef.current.editorContainer.parentNode;

	return {
		top: node.offsetTop + editorRoot.offsetTop + OFFSET,
	};
};

const initialState = {
	menuVisible: false,
	position: {
		top: OFFSET,
	},
	tooltipVisible: false,
};

const addTooltipReducer = (state, action = { type: null }) => {
	switch (action.type) {
		case HIDE_ADD_TOOLTIPE_BUTTON:
			return {
				...state,
				tooltipVisible: false,
			};
		case HIDE_ADD_TOOLTIP_MENU:
			return {
				...state,
				menuVisible: false,
			};
		case SHOW_ADD_TOOLTIP_BUTTON:
			return {
				...state,
				position: calculateAddTooltipPosition(action.editorState, action.editorRef),
				tooltipVisible: true,
			};
		case SHOW_ADD_TOOLTIP_MENU:
			return {
				...state,
				menuVisible: true,

			};
		default:
			throw new Error(`Invalid action type: ${action.type}.`);
	}
};

AddTooltip.propTypes = {
	editorRef: PropTypes.object.isRequired,
	editorState: PropTypes.instanceOf(EditorState).isRequired,
	setEditorState: PropTypes.func.isRequired,
};

function AddTooltip({
	editorRef,
	editorState,
	setEditorState,
}) {
	const [state, dispatch] = useReducer(addTooltipReducer, initialState);

	useEffect(() => {
		const selection = editorState.getSelection();

		if (selection.isCollapsed()) {
			const startKey = selection.getStartKey();
			const selectedBlock = editorState
			  .getCurrentContent()
			  .getBlockForKey(startKey);

			if (isInListMode(selectedBlock)) {
				return;
			}

			const selectedBlockText = selectedBlock.getText();

			if (selectedBlockText === '') {
				dispatch({
					type: SHOW_ADD_TOOLTIP_BUTTON,
					editorRef,
					editorState,
				});
			} else {
				dispatch({
					type: HIDE_ADD_TOOLTIPE_BUTTON,
				});
			}
		}
	}, [editorState]);

	const className = cn('addTooltip z5', {
		addTooltipVisible: state.tooltipVisible,
		addTooltipMenuVisible: state.menuVisible,
	});

	const closeMenu = useCallback(evt => {
		if (evt) {
			try {
				evt.preventDefault();
				evt.stopPropagation();
			} catch (err) {}
		}

		dispatch({
			type: HIDE_ADD_TOOLTIP_MENU,
		});
	});

	// We need to cancel the event's default
	// and propagation when closing the menu
	// via the button, otherwise the form is submitted
	// (possible MUI bug?). But when the user clicks
	// outside of this menu, we don't want to worry
	// about the event.
	const handleClickAway = useCallback(_evt => {
		dispatch({
			type: HIDE_ADD_TOOLTIP_MENU,
		});
	});

	const showMenu = useCallback(evt => {
		evt.stopPropagation();
		evt.preventDefault();

		dispatch({
			type: SHOW_ADD_TOOLTIP_MENU,
		});
	});

	const thisRef = useRef(null);
	useClickListener(thisRef, handleClickAway);

	return (
		<div
			className={className}
			ref={thisRef}
			style={state.position}
		>
			<AddTooltipButton
				onClick={state.menuVisible ? closeMenu : showMenu}
				type="button"
				className="addTooltipToggle"
			>
				<MdAdd />
			</AddTooltipButton>
			<AddTooltipDispatch.Provider value={dispatch}>
				<AddTooltipMenu
					closeMenu={closeMenu}
					editorState={editorState}
					setEditorState={setEditorState}
					visible={state.menuVisible}
				/>
			</AddTooltipDispatch.Provider>
		</div>
	);
}

export default AddTooltip;
