import React from 'react';

import AnnotationEnvironment from '../components/AnnotationEnvironment';


class AnnotationEnvironmentContainer extends React.Component {
  constructor(props) {
    super(props);
    const annotations = [];
    const _annotations = window.__orpheus__.Annotations || [];
    const replies = [];

    for (let key in _annotations) {
      if (_annotations[key].parent_comment_id) {
        replies.push(_annotations[key]);
      } else {
        _annotations[key].replies = [];
        annotations.push(_annotations[key]);
      }
    }

    replies.forEach(reply => {
      annotations.forEach(annotation => {
        if (annotation.ID === parseInt(reply.parent_comment_id)) {
          annotation.replies.push(reply);
        }
      })
    });

    this.state = {
      annotations,
    };
  }

  render() {
    return (
      <AnnotationEnvironment
        annotations={this.state.annotations}
      />
    );
  }
}

export default AnnotationEnvironmentContainer;
