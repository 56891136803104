import React from 'react';
import autoBind from 'react-autobind';
import _ from 'underscore';

import ReactReader from "./react-reader/ReactReader";
import NotFound from './NotFound'


class EpubReader extends React.Component {

  constructor(props) {
    super(props);
    const Orpheus = window.__orpheus__ || {};
    let readingLocation = null;

    const localStorageLastLocation = localStorage.getItem(Orpheus.ePubUrl);
    if (localStorageLastLocation) {
      readingLocation = localStorageLastLocation;
    }

    this.state = {
      readingLocation, // "epubcfi(/6/2[cover]!/6)",
      title: Orpheus.ePubTitle,
      slug: Orpheus.ePubSlug,
      ePubUrl: Orpheus.ePubUrl,
    };

    autoBind(this);
  }

  componentDidUpdate = () => {
    const cssLink = document.createElement("link");
    cssLink.href = "/wp-content/themes/orpheus/static/css/reading.css";
    cssLink.rel = "stylesheet";
    cssLink.type = "text/css";
    const frames = document.getElementsByTagName("iframe");
    let images = [];
    for (let i = 0; i < frames.length; i++) {
      if (frames[i] && frames[i].contentDocument && frames[i].contentDocument.head) {
        frames[i].contentDocument.head.appendChild(cssLink);
      }
    }
  }

  onLocationChanged = (epubcifi) => {
    const Orpheus = window.__orpheus__ || {};

    this.setState({
      readingLocation: epubcifi,
    });
    localStorage.setItem(Orpheus.ePubUrl, epubcifi)
  }


  render() {
    const { ePubUrl, title, slug } = this.state;

    return (
      <div
        className="epubReader"
        style={{
          height: "100vh",
          width: "100vw",
          padding: '0',
          background: '#fff'
        }}
      >

        {(ePubUrl && title) ?
          <ReactReader
            url={ePubUrl}
            title={title}
            slug={slug}
            location={this.state.readingLocation}
            locationChanged={this.onLocationChanged.bind(this)}
            style={{
              p: {
                background: "blue !important",
              },
            }}
            swipeable={window.innerWidth < 750}
          />
        :
          <NotFound />
        }
      </div>
    );
  }
}

export default EpubReader;
