import { RichUtils } from 'draft-js';

const removeLinkEntity = (editorState) => {
	const selection = editorState.getSelection();

	if (!selection.isCollapsed()) {
		return RichUtils.toggleLink(editorState, selection, null);
	}

	return editorState;
};

export default removeLinkEntity;
