/**
 * @prettier
 */

import { EditorState, Modifier, RichUtils, SelectionState } from 'draft-js';

const ORDERED_LIST_ITEM_REGEXP = /^\d\./;
const UNORDERED_LIST_ITEM_REGEXP = /^-|\*\s/;

const getListType = chars => {
  if (ORDERED_LIST_ITEM_REGEXP.test(chars)) {
    return 'ordered-list-item';
  } else if (UNORDERED_LIST_ITEM_REGEXP.test(chars)) {
    return 'unordered-list-item';
  }

  return null;
};

export const listTypeCandidate = currentBlock => {
  const blockText = currentBlock.getText();
  const blockType = currentBlock.getType();
  const listType = getListType(blockText);
  if (blockType !== listType) {
    return listType;
  }

  return null;
};

export const replaceListStartText = (editorState, currentBlock, listType) => {
  const blockKey = currentBlock.getKey();
  const blockSelection = new SelectionState({
    anchorKey: blockKey,
    anchorOffset: 0,
    focusKey: blockKey,
    // this ternary is a little magic-number-y, but it
    // means we can avoid a costly regexp.match() call.
    focusOffset: listType === 'ordered-list-item' ? 3 : 2,
  });
  const contentState = Modifier.replaceText(
    editorState.getCurrentContent(),
    blockSelection,
    ''
  );

  editorState = EditorState.push(editorState, contentState, 'remove-range');

  return EditorState.forceSelection(
    editorState,
    contentState.getSelectionAfter()
  );
};

export const makeListItem = (editorState, currentBlock, listType) => {
  editorState = replaceListStartText(editorState, currentBlock, listType);

  return RichUtils.toggleBlockType(editorState, listType);
};
