const initializeFileReader = () => {
	const reader = new FileReader();

	reader.onabort = e => {
		console.warn(`File upload aborted. Reason: ${e}`);
	}

	reader.onerror = e => {
		console.error(`There was an error uploading the file. ${e}`);
	}

	return reader;
};

export default initializeFileReader;
