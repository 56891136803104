/**
 * @prettier
 */

import hashtag from 'hashtag-regex';

const HASHTAG_REGEX = hashtag();

export default HASHTAG_REGEX;
export { hashtag as HashtagFactory };
