/**
 * @prettier
 */

import * as React from 'react';
import { Editor, EditorState } from 'draft-js';

export const SET_EDITOR_REF = '@@OrpheusEditor/SET_EDITOR_REF';
export const SET_EDITOR_STATE = '@@OrpheusEditor/SET_EDITOR_STATE';
export const SET_FORMATTING_TOOLTIP_CONTENT =
  '@@OrpheusEditor/SET_FORMATTING_TOOLTIP_CONTENT';
export const SET_LINK_INPUT = '@@OrpheusEditor/SET_LINK_INPUT';
export const SET_TOOLTIP = '@@OrpheusEditor/SET_TOOLTIP';
export const SET_ADD_TOOLTIP = '@@OrpheusEditor/SET_ADD_TOOLTIP';

export const setEditorRef = editorRef => ({
  type: SET_EDITOR_REF,
  editorRef,
});

export const setEditorState = editorState => ({
  type: SET_EDITOR_STATE,
  editorState,
});

export const setFormattingTooltipContent = content => ({
  type: SET_FORMATTING_TOOLTIP_CONTENT,
  content,
});

export const setTooltip = tooltip => ({
  type: SET_TOOLTIP,
  tooltip,
});

export const setAddTooltip = addTooltip => ({
  type: SET_ADD_TOOLTIP,
  addTooltip,
});

export default {
  setAddTooltip,
  setEditorState,
  setFormattingTooltipContent,
  setTooltip,
  setEditorRef,
};
