import { useEffect } from 'react';

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useClickListener(ref, cb) {
	useEffect(() => {
		function handleClickOutside(event) {
			if (ref.current && !ref.current.contains(event.target)) {
				cb(event);
			}
		}

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [ref]);
}

export default useClickListener;