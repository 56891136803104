import React from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import autobind from 'react-autobind';
import moment from 'moment';

// draft tools
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { convertToHTML, convertFromHTML } from 'draft-convert';

// our editor component
import Editor from '../../../orpheus-editor/components/Editor';

// lib
import Utils from '../../lib/utils';



class AnnotationCreate extends React.Component {
	constructor(props) {
		super(props);
		const userId = Cookies.get('userId');

		this.state = {
			localContent: EditorState.createEmpty(),
		};

		autobind(this);
	}

	handleSave = (event) => {
		event.preventDefault();

		const { localContent } = this.state;
		// const error = this.validateStateForSubmit();
		//
		// if (error.errors) {
		// 	this.showSnackBar(error);
		//
		// } else {
		// 	// create html from editorState's content

		const textHtml = convertToHTML(localContent.getCurrentContent());
		this.saveAnnotation(textHtml);

		// }
	}

	saveAnnotation = (annotationHTML) => {
		const { ID, isReply } = this.props;
		const userId = window.__orpheus__.userId;

    const $paragraphs = $("#main p");
    const $annotatedParagraph = $($paragraphs[this.props.n]);

		// add a new revision
		if (ID && !isReply) {
			const newRevision = {
				content: annotationHTML,
				created: new Date(),
			};

			// Add revision to set
			this.annotationAddRevision(
				ID,
				newRevision,
			);

			// TODO: handle loading state for response with errors
			//	console.error(`Error adding revision to annotation: ${err.message}`);

		} else {
			let postId = 0;
			$('body')[0].classList.forEach(classname => {
				if (classname.indexOf('postid-') >= 0) {
					postId = classname.replace('postid-', '');
				}
			});

			// create a new annotation or reply
			const newAnnotation = {
				post_author: userId,
				content: annotationHTML,
				paragraph_n: this.props.paragraphN,
				on_post: postId,
			};

			// If the ID is specified and isReply is true, save ID
			// as the parentCommentId of the annotation
			if (isReply && ID) {
				newAnnotation.parentCommentId = ID;
				if (this.props.closeReply) {
					this.props.closeReply();
				}
			}

			// Insert new annotation
			this.annotationCreate(newAnnotation);
		}

		// close the edit mode of the text field
		this.props.closeEditMode();
	}

	annotationCreate(newAnnotation) {
		console.log('PUT');
		console.log(newAnnotation);
		fetch(`/wp-json/chs/v1/annotation/`, {
		  method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				'X-WP-Header' : chsScriptVars.nonce,
			},
			body: JSON.stringify(newAnnotation),
		})
		.then(res => res.json())
		.then(res => {
			console.log(res)
			window.location.reload();
		});
	}

	annotationAddRevision(ID, newRevision) {
		console.log('POST');
		console.log(ID);
		console.log(newRevision);
		fetch(`/wp-json/chs/v1/annotation/${ID}`, {
		  method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'X-WP-Header' : chsScriptVars.nonce,
			},
			body: JSON.stringify(newRevision),
		})
		.then(res => res.json())
		.then(res => {
			console.log(res)
			window.location.reload();
		});

	}


	handleCancel = () => {
		this.props.closeEditMode();
	}

	showSnackBar = (error) => {
		this.setState({
			snackbarOpen: error.errors,
			snackbarMessage: error.errorMessage,
		});
		setTimeout(() => {
			this.setState({
				snackbarOpen: false,
			});
		}, 4000);
	}

	validateStateForSubmit = () => {
		let errors = false;
		let errorMessage = 'Missing annotation data:';

		if (this.state.textValue === '<p><br></p>' || !this.state.textValue) {
			errors = true;
			errorMessage += ' comment text,';
		}

		if (errors === true) {
			errorMessage = errorMessage.slice(0, -1);
			errorMessage += '.';
		}

		return {
			errors,
			errorMessage,
		};
	}

	setLocalContent (localContent) {
		this.setState({
			localContent,
		});
	}

	render = () => {
		const { localContent } = this.state;
		const username = window.__orpheus__.username;
		const displayName = window.__orpheus__.displayName;
		const userId = window.__orpheus__.userId;
		const userAvatar = window.__orpheus__.userAvatar;
		const defaultAvatar = "/wp-content/themes/orpheus/static/images/author_default.jpg";

		return (
			<div
				className="annotationThread annotationCreate"
        style={{
          top: this.props.top,
        }}
			>
				<div className="annotations">
					<div className="annotation ">
						<div className="annotationBody">
							<div className="annotationUpper">
								<div className="annotationUser">
									<div
										className="avatarIcon"
										style={{
											backgroundImage: `url(${userAvatar ? userAvatar : defaultAvatar})`,
										}}
									/>
									<span className="name">
										{displayName ? displayName : username}
									</span>
								</div>
								<div
									className="revisions"
								>
									<span className="revisionsActiveLabel">
										{moment().format('D MMM YYYY')}
									</span>
									{/*}<i className="mdi mdi-dots-vertical revisionsToggle" />*/}
								</div>
								{/* revisions.length > 1 ?
									<div
										className="revisions"
										onTouchTap={this.handleRevisionsTouchTap.bind(this)}
									>
										<span className="revisionsActiveLabel">
											{/* {moment(activeRevision.created).format('D MMM YYYY')}
											}
										</span>
										<i className="mdi mdi-dots-vertical revisionsToggle" />
									</div>
								:
									<div
										className="revisions revisionsNoHover"
									>
										<span className="revisionsActiveLabel">
											{/*
											{moment(activeRevision.created).format('D MMM YYYY')}
											}
										</span>
									</div>
								*/}
								{/*
								<Popover
									open={this.state.revisionsDropdownOpen}
									anchorEl={this.state.anchorEl}
									anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
									targetOrigin={{horizontal: 'left', vertical: 'top'}}
									onRequestClose={this.handleRequestClose}
								>
									<Menu>
										{revisions.map((revision, i) => (
											<MenuItem
												key={i}
												primaryText={moment(activeRevision.created).format('D MMM YYYY')}
												onClick={this.selectRevision.bind(this, revision)}
											/>
										))}
									</Menu>
								</Popover>
								*/}
							</div>
							<div
								className="annotationText"
							>
								<Editor
									editorState={localContent}
									handleChange={this.setLocalContent.bind(this)}
								/>
							</div>
							<div className="annotationActions">
								<button
									className="annotationAction annotationActionSave replyToggle"
									onClick={this.handleSave.bind(this)}
								>
									Save
								</button>
								<button
									className="annotationAction s.annotationActionCancel"
									onClick={this.handleCancel.bind(this)}
								>
									Cancel
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

AnnotationCreate.propTypes = {
};

export default AnnotationCreate;
