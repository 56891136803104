/**
 * @prettier
 */

import React from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { withSnackbar } from 'notistack';
import { LinearProgress } from '@material-ui/core';

// components
import AddTooltipMenuItemButton from '../../AddTooltipMenuItemButton';

// lib
import initializeFileReader from '../../../../../lib/initializeFileReader';
import insertEntity from '../../../../../lib/insertEntity';

// icons
import { MdImage } from 'react-icons/md';

class AddImageButton extends React.Component {
	static propTypes = {
		closeMenu: PropTypes.func.isRequired,
		editorState: PropTypes.object.isRequired,
		enqueueSnackbar: PropTypes.func.isRequired,
		setEditorState: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.fileInputRef = React.createRef();
		this.fileReader = initializeFileReader();

		this.state = {
			uploading: false,
		};
	}

	handleAddImage = () => {
		this.fileInputRef.current.click();
	};

	handleFileInput = e => {
		const { files } = e.target;
		const file = files[0];

		this.fileReader.onload = async e => {
			await this.setState({ uploading: true });
			try {
				const filename = `${shortid.generate()}-${file.name.replace(
					/[^\w\d_\-\.]+/gi,
					''
				)}`;
				file.filename = filename;
				const response = await fetch(`/sign_s3/?file_name=${filename}`);

				if (response.ok) {
					const data = await response.json();
					const { fields, url } = data;
					const formData = new FormData();

					Object.keys(fields).forEach(k => {
						formData.append(k, fields[k]);
					});

					formData.append('file', file);
					const put = await fetch(url, {
						body: formData,
						method: 'POST',
					});

					if (put.ok) {
						this.handleUploadFinish({ filename });
					}
				} else {
					throw response;
				}
			} catch (err) {
				this.handleUploadError(err);
			}
		};

		try {
			this.fileReader.readAsDataURL(file);
		} catch (e) {
			this.handleUploadError(e);
		}
	};

	handleUploadError = e => {
		console.error(`Error uploading file: ${e.message}`);

		const { closeMenu, enqueueSnackbar } = this.props;

		closeMenu();

		enqueueSnackbar("Sorry, we weren't able to upload that image.", {
			variant: 'error',
		});
	};

	handleUploadProgress = () => {};

	handleUploadFinish = ({ filename }) => {
		const { closeMenu, editorState, setEditorState } = this.props;

		const newEditorState = insertEntity(editorState, {
			entityType: 'IMAGE',
			entityData: {
				src: `https://new-alexandria.s3.us-east-2.amazonaws.com/${filename}`,
			},
		});

		setEditorState(newEditorState);


		// wait a tick before hiding the menu
		setTimeout(closeMenu, 0);

		this.setState({ uploading: false });
	};

	render() {
		return (
			<AddTooltipMenuItemButton className={`${this.state.uploading ? '-uploading' : ''}`} onClick={this.handleAddImage}>
				{this.state.uploading ?
					<div style={{ width: '60px', height: '60px', paddingTop: '30px', }}>
						<LinearProgress />
					</div>
				:
					<MdImage />
				}
				<span>Image</span>
				<input
					type="file"
					style={{ display: 'none' }}
					ref={this.fileInputRef}
					onChange={this.handleFileInput}
				/>
			</AddTooltipMenuItemButton>
		);
	}
}

export default withSnackbar(AddImageButton);
