/**
 * @prettier
 */

import React from 'react';
import IconClose from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import _s from 'underscore.string';
import autoBind from 'react-autobind';
import axios from 'axios';
import { connect } from 'react-redux';
// redux
import editorActions from '../../../actions';

// components
import Card from '../../../../../components/common/cards/Card';

import CardLoadingItem from '../../../../../components/common/cards/CardLoadingItem';
// lib
import removeBlock from '../../../lib/removeBlock';

import { IconButton } from '@material-ui/core';

const getImageUrl = (data = {}) => {
	if (data.images && data.images.length) {
		return data.images[0].url;
	}

	return data.thumbnailUrl;
};

class EmbedBlock extends React.Component {
	static propTypes = {
		block: PropTypes.shape({
			getKey: PropTypes.func,
		}),
		editorState: PropTypes.object,
		setEditorState: PropTypes.func.isRequired,
		url: PropTypes.string.isRequired,
		userId: PropTypes.string,
	};

	constructor(props) {
		super(props);

		this.state = {
			embedData: null,
			error: null,
			loaded: false,
		};

		autoBind(this);
	}

	componentDidMount() {
		this.mounted = true;

		this._fetchData();
	}

	componentWillUnmount() {
		this.mounted = false;
	}

	componentDidUpdate(prevProps) {
		if (this.props.url && prevProps.url !== this.props.url) {
			this._fetchData();
		}
	}

	removeBlock() {
		const { block, editorState, setEditorState } = this.props;

		if (!editorState) {
			console.error('No editorState in EmbedBlock! Cannot update.');

			return null;
		}

		setEditorState(removeBlock(editorState, block.getKey()));
	}

	renderCard() {
		const { embedData } = this.state;
		const url = embedData.url;
		const imageUrl = getImageUrl(embedData);
		const title = embedData.title;
		const description = embedData.description;

		if (imageUrl) {
			return (
				<Card
					href={url}
					key={_s.slugify(title)}
					className="listItemCard"
					title={title}
					textShort={_s.prune(description, 270)}
					assetImageSrc={imageUrl}
					assetImageWidth={1000}
					assetImageAlt={title}
				/>
			);
		} else {
			return (
				<Card
					href={url}
					key={_s.slugify(title)}
					className="listItemCard"
					title={title}
					textLong={_s.prune(description, 1500)}
				/>
			);
		}
	}

	render() {
		const { loaded } = this.state;

		return (
			<div className="embedBlock">
				<div className="embeddedContent">
					{!loaded ? <CardLoadingItem /> : this.renderCard()}
					{this.renderRemoveButton()}
				</div>
			</div>
		);
	}

	renderRemoveButton() {
		if (
			this.props.userId &&
			(location.pathname.endsWith('/edit/') ||
				location.pathname.endsWith('/create/'))
		) {
			return (
				<div className="embedBlockRemove">
					<IconButton onClick={this.removeBlock}>
						<IconClose />
					</IconButton>
				</div>
			);
		}

		return null;
	}

	async _fetchData() {
		const { url } = this.props;

		// if data isn't already loaded, get it from embed.ly
		const response = await axios({
			method: 'get',
			url: `//api.embed.ly/1/extract?key=${process.env.REACT_APP_EMBEDLY_API_KEY}&url=${url}`,
		});

		// we're checking whether the component is mounted
		// here in case the response starts before unmount
		// but ends after
		if (!this.mounted) return null;

		this.setState({
			embedData: response.data,
			loaded: true,
		});
	}
}

const mapStateToProps = state => ({
	editorState: state.editor.editorState,
	userId: state.auth && state.auth.userId,
});

const mapDispatchToProps = {
	setEditorState: editorActions.setEditorState,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(EmbedBlock);
