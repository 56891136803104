/**
 * @prettier
 */

import * as React from 'react';

import ImageBlock from '../ImageBlock';
// import ItemBlock from '../ItemBlock';
import EmbedBlock from '../EmbedBlock';
import VideoBlock from '../VideoBlock';

const ENTITY_TYPE_MAP = {
	EMBED: EmbedBlock,
	IMAGE: ImageBlock,
	// ITEM: ItemBlock,
	LINK: null,
	PHOTO: null,
	TOKEN: null,
	VIDEO: VideoBlock,
};

const attemptRescue = block => {
	const blockData = block.getData();
	const blockType = block.getType();
	const mediaType = blockData.get('type') || blockType;

	let Block;
	switch (blockType) {
		case 'atomic':
			Block = ENTITY_TYPE_MAP[mediaType];
			break;
		case 'image':
			return <ImageBlock block={block} src={blockData.src} />;
		case 'embed':
			return <EmbedBlock block={block} url={blockData.url} />;
		default:
			break;
	}

	if (!Block) {
		// this if-statement is triggered because we're not exiting the entity
		// after insertion? so text gets put inside the entity, which is bad?
		console.warn(`Unsupported Block data type: ${mediaType}.
      Entity: ${JSON.stringify(block.toJSON())}`);

		return null;
	}

	return <Block block={block} />;
};

const MediaBlock = ({ block, contentState }) => {
	// The reason we can hard-code getEntityAt(0) here
	// is because we're working with atomic blocks.
	const entityKey = block.getEntityAt(0);

	if (entityKey) {
		const entity = contentState.getEntity(entityKey);
		const entityType = entity.getType();
		const entityData = entity.getData();

		switch (entityType) {
			case 'EMBED':
				return <EmbedBlock block={block} url={entityData.url} />;
			case 'IMAGE':
				return <ImageBlock src={entityData.src} />;
			case 'VIDEO':
				return (
					<VideoBlock src={entityData.src} videoType={entityData.videoType} />
				);
			default:
				console.warn(`Unsupported Entity type: ${entityType}.
		      Entity: ${JSON.stringify(entity.toJSON())}`);
				return null;
		}
	}

	console.warn(
		'No entityKey found on block. This probably happened because ' +
			'the entity was stored as block `data` rather than in the `entityMap`.',
		`Block: ${JSON.stringify(block.toJSON())}`
	);

	return attemptRescue(block);
};

export default MediaBlock;
