/**
 * @prettier
 */

import React from 'react';
import PropTypes from 'prop-types';

const AddTooltipButton = props => (
	<button
		className={`addTooltipButton ${props.className}`}
		onClick={props.onClick}
	>
		<div className="addTooltipInner">{props.children}</div>
	</button>
);

AddTooltipButton.propTypes = {
	children: PropTypes.any,
	className: PropTypes.string,
	onClick: PropTypes.func,
};

export default AddTooltipButton;
