import React from 'react';

import Card from '../Card';


const CardLoadingItem = () => (
	<Card
		loading
		to=""
		title = " "
		textShort = " "
		assetImageSrc = "http://"
		assetImageWidth = ""
		assetImageHeight = ""
		assetImageAlt = ""
		metadata = {[
			{ metaLabel: '', metaValue: '' },
			{ metaLabel: '', metaValue: '' },
		]}
	/>
);

export default CardLoadingItem;
