/**
 * @prettier
 */

import { HashtagFactory } from './hashtagRegex';

function getHashtagForCursor(selectionState, contentState) {
	// we need to instantiate a new regex on every call
	// because RegExp.prototype.exec() keeps the
	// state from every call until it fails --- if we
	// don't clear it in this way, this function will fail on every
	// other call
	const HASHTAG_REGEX = HashtagFactory();

	if (selectionState.isCollapsed()) {
		const blockKey = selectionState.getEndKey();
		const block = contentState.getBlockForKey(blockKey);
		const blockText = block.getText();
		const selectionOffset = selectionState.getEndOffset();
		let matchArr;

		while (
			(matchArr = HASHTAG_REGEX.exec(blockText)) !== null &&
			matchArr.index <= selectionOffset
		) {
			if (
				matchArr[0].indexOf(
					blockText.substring(matchArr.index, selectionOffset)
				) === 0
			) {
				return matchArr[0];
			}
		}
	}

	return null;
}

export default getHashtagForCursor;
