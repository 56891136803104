/**
 * @prettier
 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import CardLoadingItem from '../../../../../components/common/cards/CardLoadingItem';

const getVimeoEmbedSrc = async src => {
	const response = await fetch(`https://vimeo.com/api/oembed.json?url=${src}`);
	const json = await response.json();

	return json.html;
};

// FIXME: YouTube's oEmbed API doesn't set CORS.
// We could proxy the request through our own servers,
// which would be the ideal solution.
// const getYouTubeEmbedSrc = async src => {
// 	const response = await fetch(
// 		`https://youtube.com/oembed?url=${src}&format=json`
// 	);
// 	const json = await response.json();

// 	return json.html;
// };

const getYouTubeEmbedSrc = src => src.replace('watch?v=', 'embed/');

const iFrameFetchers = {
	vimeo: getVimeoEmbedSrc,
	youtube: getYouTubeEmbedSrc,
};

function VideoBlock({ src, videoType }) {
	const [iFrame, setIFrame] = useState('');
	const [loading, setLoading] = useState(true);

	if (!src) return null;

	useEffect(() => {
		async function fetchData() {
			const fetcher = iFrameFetchers[videoType];
			if (!fetcher) return setLoading(false);

			const html = await fetcher(src);

			setLoading(false);
			setIFrame(html);
		}

		fetchData();
	}, [src, videoType]);

	let inner = null;
	if (loading) {
		inner = <CardLoadingItem />;
	} else if (!loading && !iFrame) {
		inner = <p className="error">Failed to load {src}.</p>;
	} else if (videoType === 'vimeo') {
		inner = <div dangerouslySetInnerHTML={{ __html: iFrame }} />;
	} else {
		inner = (
			<div>
				<iframe
					width="640px"
					height="340px"
					src={getYouTubeEmbedSrc(src)}
					frameBorder="0"
					allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
					allowFullScreen
					style={{
						left: 0,
						top: 0,
						width: '100%',
					}}
				/>
			</div>
		);
	}

	return <figure className="videoBlock">{inner}</figure>;
}

VideoBlock.propTypes = {
	src: PropTypes.string.isRequired,
	videoType: PropTypes.oneOf(['vimeo', 'youtube']).isRequired,
};

export default VideoBlock;
