import React from 'react';
import PropTypes from 'prop-types';

import AnnotationCreate from '../AnnotationCreate';

class AnnotationDrawer extends React.Component {
  constructor(props) {
    super(props);

    const userCanAnnotate = window.__orpheus__.userCanAnnotate || false;

    this.state = {
			userCanAnnotate,
    };
  }

	render() {
		return (
			<div
				className="annotationDrawer"
			>
				<AnnotationCreate
          top={this.props.top}
          closeEditMode={this.props.closeEditMode}
          paragraphN={this.props.paragraphN}
				/>
			</div>
		)
	}

}

export default AnnotationDrawer;
