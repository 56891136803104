/**
 * @prettier
 */
import React from 'react';
import PropTypes from 'prop-types';

import FormattingTooltipItem from '../FormattingTooltipItem';
import FormattingTooltipLink from '../FormattingTooltipLink';

FormattingTooltipButtons.propTypes = {
  editorState: PropTypes.object.isRequired,
  overrideContent: PropTypes.func.isRequired,
  setEditorState: PropTypes.func.isRequired,
};

function FormattingTooltipButtons({
  editorState,
  overrideContent,
  setEditorState,
}) {
  return (
    <div className="formattingTooltipButtons">
      <FormattingTooltipItem
        editorState={editorState}
        setEditorState={setEditorState}
        icon="format-title"
        blockType="header-one"
      />
      <FormattingTooltipItem
        editorState={editorState}
        setEditorState={setEditorState}
        icon="format-title"
        blockType="header-two"
      />
      <FormattingTooltipItem
        editorState={editorState}
        setEditorState={setEditorState}
        icon="format-bold"
        inlineStyle="BOLD"
      />
      <FormattingTooltipItem
        editorState={editorState}
        setEditorState={setEditorState}
        icon="format-italic"
        inlineStyle="ITALIC"
      />
      <FormattingTooltipItem
        editorState={editorState}
        setEditorState={setEditorState}
        icon="format-blockquote"
        blockType="blockquote"
      />
      <FormattingTooltipLink
        editorState={editorState}
        overrideContent={overrideContent}
      />
    </div>
  );
}

export default FormattingTooltipButtons;
