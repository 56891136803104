const draftJsBlockTypes = [
	'unstyled',
	'paragraph',
	'header-one',
	'header-two',
	'header-three',
	'header-four',
	'header-five',
	'header-six',
	'unordered-list-item',
	'ordered-list-item',
	'blockquote',
	'code-block',
	'atomic',
];

export default draftJsBlockTypes;

export const LIST_ITEM_BLOCK_TYPES = [
	'ordered-list-item',
	'unordered-list-item'
];
