import React from 'react';
import ReactDOM from 'react-dom';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';

import Utils from './lib/utils';
import AnnotationEnvironmentContainer from './containers/AnnotationEnvironmentContainer';
import configureStore from './store/configureStore';


const store = configureStore();

class Annotations extends React.Component {
	componentWillMount = () => {
		// Check if browser supports retina
		Utils.checkIsRetina();
	}

	render  = () => {
		return (
			<Provider store={store} >
				<MuiThemeProvider>
					<SnackbarProvider anchorOrigin={{
		        vertical: 'bottom',
		        horizontal: 'right',
	    		}}>
						<AnnotationEnvironmentContainer />
					</SnackbarProvider>
				</MuiThemeProvider>
			</Provider>
		);
	}
}

export default Annotations;
