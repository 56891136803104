const getBlock = (editorState, keyGetter) => {
	try {
		const selectionState = editorState.getSelection();
	  const contentState = editorState.getCurrentContent();

		return contentState.getBlockForKey(selectionState[keyGetter]());
	} catch (e) {
		console.error(e);

		return null;
	}
};

export const getEndBlock = (editorState) => getBlock(editorState, 'getEndKey');

export const getStartBlock = (editorState) => getBlock(editorState, 'getStartKey');

/*
 Get currentBlock in the editorState.
 via https://github.com/michelson/dante2/blob/master/src/editor/model/index.js
*/

// TODO?: (charles) We might want to deprecate getCurrentBlock()
// in favor of more explicit Block-getters like those below.
const getCurrentBlock = (editorState) => getStartBlock(editorState);

export default getCurrentBlock;
