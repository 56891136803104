const containsLinkEntity = editorState => {
	if (!editorState) {
		return false;
	}

	const selection = editorState.getSelection();
	const selectionKey = selection.getStartKey();
	const content = editorState.getCurrentContent();
	const currentBlock = content.getBlockForKey(selectionKey);
	const entityKey = currentBlock.getEntityAt(selection.getStartOffset());

	if (!entityKey) return false;

	const entity = content.getEntity(entityKey);

	return entity.getType() === 'LINK';
};

export default containsLinkEntity;
