const Utils = {
	objToList: (obj) => {
		const arr = [];
		for (let key in obj) { // eslint-disable-line
			arr.push(obj[key]);
		}
		return arr;
	},
	getUserName: (user) => {
		let name = '';

		if (user.emails && user.emails.length) {
			name = user.emails[0].address;
		}

		if (user.username) {
			name = user.username;
		}

		if (user.profile && user.profile.name) {
			name = user.profile.name;
		}

		return name;
	},
	checkIsRetina: () => {
		/*
		 * Excerpted from Retina.js @ https://github.com/xorcery/retinajs/blob/master/src/retina.js
		 * but I didn't add retina.js to dependencies since it was one line
		 */

		/*
		 * Determine whether or not `window` is available.
		 */
		const hasWindow = typeof window !== 'undefined';

		/*
		 * Get the device pixel ratio per our environment.
		 * Default to 1.
		 */
		const environment = hasWindow ? (window.devicePixelRatio || 1) : 1;

		if (environment === 2) {
			$('html').addClass('is-retina');
		}
	},
	trunc: (str, length) => {
		const ending = ' ...';
		let trimLen = length;
		str = str.replace(/<(?:.|\n)*?>/gm, '');

		if (trimLen == null) {
			trimLen = 100;
		}

		if (str.length > length) {
			return str.substring(0, length - ending.length) + ending;
		}

		return str;
	},

	findGetParameter: (parameterName) => {
		let result = null;
		let tmp = [];

		location.search
			.substr(1)
			.split('&')
			.forEach((item) => {
				tmp = item.split('=');
				if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
			});

		return result;
	},

	paramStringToObject: (str) => {
		const queryDict = {};

		str.split('&').forEach((item) => {
			queryDict[item.split('=')[0]] = item.split('=')[1];
		});

		return queryDict;
	},

	getEnvDomain: () => {
		let domain;

		if (location.hostname.match(/\w+.chs.harvard.edu/) || location.hostname === 'chs.harvard.edu') {
			domain = 'chs.harvard.edu';
		} else if (location.hostname.match(/\w+.orphe.us/)) {
			domain = 'orphe.us';
		} else if (
			location.hostname.match(/\w+.chs..harvard.local/)
		|| location.hostname === 'chs.harvard.local') {
			domain = 'chs.harvard.local';
		} else if (
			location.hostname.match(/\w+.chs.local/)
		|| location.hostname === 'chs.local') {
			domain = 'chs.local';
		}

		return domain;
	},

};

export default Utils;
