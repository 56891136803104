import React from 'react';
import PropTypes from 'prop-types';
import autoBind from 'react-autobind';
import moment from 'moment';

import Popover from 'material-ui/Popover';
import Menu from 'material-ui/Menu';
import MenuItem from 'material-ui/MenuItem';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import linkifyHtml from 'linkifyjs/html';

import { EditorState, ContentState, convertToRaw, convertFromRaw, convertFromHTML } from 'draft-js';
import { convertToHTML, } from 'draft-convert';

import Editor from '../../../orpheus-editor/components/Editor';

import muiTheme from '../../lib/muiTheme';
import Utils from '../../lib/utils';
import AnnotationReplyCreate from '../AnnotationReplyCreate';


class Annotation extends React.Component {
	constructor(props) {
		super(props);

		let userCanEdit = false;
    const userId = window.__orpheus__.userId || null;
		if (userId && parseInt(userId) === parseInt(props.author.ID)) {
			userCanEdit = true;
		}

		let localContent;
		const blocksFromHTML = convertFromHTML(linkifyHtml(props.post_content));
		console.log(linkifyHtml(props.post_content));
		console.log(blocksFromHTML)
		const contentState = ContentState.createFromBlockArray(
		  blocksFromHTML.contentBlocks,
		  blocksFromHTML.entityMap,
		);

		localContent = EditorState.createWithContent(contentState);

		this.state = {
			editMode: false,
			showCreateReply: false,
			activeRevision: null, // this.props.revisions[this.props.revisions.length - 1],
			revisionsDropdownOpen: false,
			userCanEdit,
			localContent,
		};

		autoBind(this);
	}


	getChildContext = () => {
		return { muiTheme: getMuiTheme(muiTheme) };
	}

	showEdit = () => {
		this.setState({
			editMode: true,
			showCreateReply: false,
		});
		this.props.showMore(true);
	}

	closeEditMode = () => {
		console.log('closeEditMode');
		this.setState({
			editMode: false,
			showCreateReply: false,
		});
	}

	showReply = () => {
		this.setState({
			showCreateReply: true,
			editMode: false,
		});
		this.props.showMore(true);
	}

	closeReply = () => {
		this.setState({
			showCreateReply: false,
		});
	}

	handleRevisionsTouchTap = (event) => {
		// This prevents ghost click.
		event.preventDefault();

		this.setState({
			revisionsDropdownOpen: true,
			anchorEl: event.currentTarget,
		});
	}

	selectRevision = (revision) => {
		this.setState({
			activeRevision: revision,
			revisionsDropdownOpen: false,
		});
	}

	handleRequestClose = () => {
		this.setState({
			revisionsDropdownOpen: false,
		});
	}

	removeAnnotation = (ID) => {
		fetch(`/wp-json/chs/v1/annotation/${ID}`, {
		  method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
				'X-WP-Header' : chsScriptVars.nonce,
			},
		})
		.then(res => res.json())
		.then(res => {
			console.log(res)
			window.location.reload();
		});

	}

	setLocalContent (localContent) {
		this.setState({
			localContent,
		});
	}

	handleSave = (event) => {
		event.preventDefault();

		const { localContent } = this.state;
		// const error = this.validateStateForSubmit();
		//
		// if (error.errors) {
		// 	this.showSnackBar(error);
		//
		// } else {
		// 	// create html from editorState's content

		const textHtml = convertToHTML(localContent.getCurrentContent());
		this.saveAnnotation(textHtml);

		// }
	}

	saveAnnotation = (annotationHTML) => {
		const { ID, isReply } = this.props;
		const userId = window.__orpheus__.userId;

    const $paragraphs = $("#main p");
    const $annotatedParagraph = $($paragraphs[this.props.n]);

		// add a new revision
		if (ID && !isReply) {
			const newRevision = {
				content: annotationHTML,
				created: new Date(),
			};

			// Add revision to set
			this.annotationAddRevision(
				ID,
				newRevision,
			);

			// TODO: handle loading state for response with errors
			//	console.error(`Error adding revision to annotation: ${err.message}`);

		} else {
			let postId = 0;
			$('body')[0].classList.forEach(classname => {
				if (classname.indexOf('postid-') >= 0) {
					postId = classname.replace('postid-', '');
				}
			});

			// create a new annotation or reply
			const newAnnotation = {
				post_author: userId,
				content: annotationHTML,
				paragraph_n: this.props.paragraphN,
				on_post: postId,
			};

			// If the ID is specified and isReply is true, save ID
			// as the parentCommentId of the annotation
			if (isReply && ID) {
				newAnnotation.parentCommentId = ID;
				if (this.props.closeReply) {
					this.props.closeReply();
				}
			}

			// Insert new annotation
			this.annotationCreate(newAnnotation);
		}

		// close the edit mode of the text field
		this.closeEditMode();
	}

	annotationCreate(newAnnotation) {
		console.log('PUT');
		console.log(newAnnotation);
		fetch(`/wp-json/chs/v1/annotation/`, {
		  method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				'X-WP-Header' : chsScriptVars.nonce,
			},
			body: JSON.stringify(newAnnotation),
		})
		.then(res => res.json())
		.then(res => {
			console.log(res)
			window.location.reload();
		});
	}

	annotationAddRevision(ID, newRevision) {
		console.log('POST');
		console.log(ID);
		console.log(newRevision);
		fetch(`/wp-json/chs/v1/annotation/${ID}`, {
		  method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'X-WP-Header' : chsScriptVars.nonce,
			},
			body: JSON.stringify(newRevision),
		})
		.then(res => res.json())
		.then(res => {
			console.log(res)
			window.location.reload();
		});

	}


	handleCancel = () => {
		this.setState({
			editMode: false,
		});
		this.props.handleReplyCancel();
	}

	handleReplyCancel = () => {
		this.setState({
			showCreateReply: false,
		});
	}

	render = () => {
		const {
			ID, post_content, author, children, replies, annotationIsReply
		} = this.props;
		const revisions = [];
		const { activeRevision, editMode, showCreateReply } = this.state;

		let userIsAuthor = false;
		const defaultAvatar = "/wp-content/themes/orpheus/static/images/author_default.jpg";
    const userCanAnnotate = window.__orpheus__.userCanAnnotate || false;

		return (
			<div className={`annotation ${annotationIsReply ? 'annotation--reply' : ''}`}>
				<div className="annotationContent">
					<div
						className="annotationBody"
					>
						<div className="annotationUpper">
							<div className="annotationUser">
								<div
									className="avatarIcon"
									style={{
										backgroundImage: `url(${author.avatar ? author.avatar : defaultAvatar})`,
									}}
								/>
								<span className="name">
									{author.display_name}
								</span>
							</div>
							<div
								className="revisions"
							>
								<span className="revisionsActiveLabel">
									{moment(this.props.post_date).format('D MMM YYYY')}
								</span>
								{/*}<i className="mdi mdi-dots-vertical revisionsToggle" />*/}
							</div>
							{/* revisions.length > 1 ?
								<div
									className="revisions"
									onTouchTap={this.handleRevisionsTouchTap.bind(this)}
								>
									<span className="revisionsActiveLabel">
										{/* {moment(activeRevision.created).format('D MMM YYYY')}
										}
									</span>
									<i className="mdi mdi-dots-vertical revisionsToggle" />
								</div>
							:
								<div
									className="revisions revisionsNoHover"
								>
									<span className="revisionsActiveLabel">
										{/*
										{moment(activeRevision.created).format('D MMM YYYY')}
										}
									</span>
								</div>
							*/}
							{/*
							<Popover
								open={this.state.revisionsDropdownOpen}
								anchorEl={this.state.anchorEl}
								anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
								targetOrigin={{horizontal: 'left', vertical: 'top'}}
								onRequestClose={this.handleRequestClose}
							>
								<Menu>
									{revisions.map((revision, i) => (
										<MenuItem
											key={i}
											primaryText={moment(activeRevision.created).format('D MMM YYYY')}
											onClick={this.selectRevision.bind(this, revision)}
										/>
									))}
								</Menu>
							</Popover>
							*/}
						</div>
						{editMode ?
							<div
								className="annotationText"
							>
								<Editor
									editorState={this.state.localContent}
									handleChange={this.setLocalContent.bind(this)}
								/>
							</div>
						:
							<div
								className="annotationText"
								dangerouslySetInnerHTML={{__html: linkifyHtml(post_content)}}
							/>
						}
						<div className="annotationActions">
							{!annotationIsReply && !this.state.editMode && !showCreateReply && userCanAnnotate ?
								<button
									className="annotationAction replyToggle"
									onClick={this.showReply.bind(this)}
								>
									<i className="mdi mdi-comment"></i>
									Reply
								</button>
							: ''}
							{this.state.userCanEdit && !this.state.editMode ?
								<React.Fragment>
									<button
										className="annotationAction annotationActionEdit"
										onClick={this.showEdit.bind(this)}
									>
										<i className="mdi mdi-pencil"></i>
										Edit
									</button>
									<button
										className="annotationAction annotationActionDelete"
										onClick={this.removeAnnotation.bind(this, ID)}
									>
										<i className="mdi mdi-trash-can-outline"></i>
										Delete
									</button>
								</React.Fragment>
							: ''}
							{this.state.editMode ?
								<React.Fragment>
									<button
										className="annotationAction annotationActionSave replyToggle"
										onClick={this.handleSave.bind(this)}
									>
										Save
									</button>
									<button
										className="annotationAction annotationActionCancel"
										onClick={this.closeEditMode.bind(this)}
									>
										Cancel
									</button>
								</React.Fragment>
							: ''}
						</div>
						{showCreateReply ?
							<AnnotationReplyCreate
								ID={ID}
								closeReply={this.closeReply.bind(this)}
								closeEditMode={this.closeEditMode.bind(this)}
								handleCancel={this.handleReplyCancel.bind(this)}
							/>
						: ''}
						{replies ?
							<div className="replies">
								{replies.map(reply => (
									<Annotation
										key={reply.ID}
										annotationIsReply
										{...reply}
									/>
								))}
							</div>
						: ''}
					</div>
				</div>
			</div>
		);
	}
}

Annotation.propTypes = {
	ID: PropTypes.number,
	revisions: PropTypes.array,
	saveAnnotation: PropTypes.func,
};

Annotation.childContextTypes = {
	muiTheme: PropTypes.object.isRequired,
};

export default Annotation;
