/**
 * @prettier
 */
import React from 'react';
import PropTypes from 'prop-types';
import autoBind from 'react-autobind';
import cn from 'classnames';
import { MdInsertLink } from 'react-icons/md';

// component
import FormattingTooltipItemButton from '../FormattingTooltipItemButton';
import LinkTextInput from '../LinkTextInput';

import containsLinkEntity from '../../../../lib/containsLinkEntity';

class FormattingTooltipLink extends React.Component {
  static propTypes = {
    editorState: PropTypes.object.isRequired,
    overrideContent: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    autoBind(this);
  }

  promptForLink(e) {
    e.preventDefault();

    this.props.overrideContent(LinkTextInput);
  }

  isActive() {
    return containsLinkEntity(this.props.editorState);
  }

  render() {
    const className = cn('formattingTooltipItem', '--link', {
      active: this.isActive(),
    });
    return (
      <FormattingTooltipItemButton
        className={className}
        onClick={this.promptForLink}
      >
        <MdInsertLink />
      </FormattingTooltipItemButton>
    );
  }
}

export default FormattingTooltipLink;
